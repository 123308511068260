import * as React from "react"
import { Link } from "gatsby"
//import { StaticImage } from "gatsby-plugin-image"
import Nav from '../components/nav';

import { useContext, useRef, useEffect } from "react"
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider";
import HumaanLogo from "./humaan-logo";

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import lightmode from "../images/svg/light-mode.svg"
import darkmode from "../images/svg/dark-mode.svg"

//import BurgerButton from "./burger-button";


gsap.registerPlugin(ScrollTrigger);



const Header = ({ siteTitle }) => {
  // const dispatch = useContext(GlobalDispatchContext);
  const gstate = useContext(GlobalStateContext);
  const gdispatch = useContext(GlobalDispatchContext);


  const navRef = useRef(null);


  const handleChange = () => {
    //setChecked(!checked);
    gdispatch({ type: 'TOGGLE_THEME' });
    // console.log(gstate.theme);
  };

  const menuClick = (e) => {
    //buttonhandler

    gdispatch({ type: 'MOBILE_NAV' })
    //console.log(gstate.mobile);
    e.preventDefault();

  }
  //gasp fucntions
  const showHeader = () => {
    if (navRef.current) {
      if (!navRef.current.classList.contains('reveal')) {
        console.log('show header');
        navRef.current.classList.remove('scrolled');
        navRef.current.classList.add('reveal');
      }
    }


  }
  const hideHeader = () => {
    if (navRef.current) {
      if (!navRef.current.classList.contains('scrolled')) {
        console.log('hide header');
        navRef.current.classList.add('scrolled');
        navRef.current.classList.remove('reveal');
      }
    }

  }


  //gsap listener
  // wait until DOM has been rendered
  useEffect(() => {
    // navRef.current.classList.remove('scrolled');
    // navRef.current.classList.remove('reveal');
    // use gsap to add scroll listeners to body
    gsap.to(navRef.current, {
      autoAlpha: 1,
      scrollTrigger: {
        trigger: "body",
        start: "top -20px",
        end: "9999",
        toggleActions: "play none play none",
        onUpdate: (self) => {
          self.direction === 1 ? hideHeader() : showHeader();
        }
      }
    });

  });



  return (

    <header>

      <div className="nav-wrapper" ref={navRef}>


        <Link
          to="/"
          aria-label="link to home"
        >

          <div className="humaan-logo">
            <HumaanLogo /></div>
        </Link>

        <button className="mobile-nav-toggle" aria-controls="primary-navigation" aria-expanded={gstate.mobile === "open" ? 'true' : 'false'} onClick={menuClick}>
{/* <BurgerButton/> */}

          <span className="sr-only">Menu</span>
        </button>


        <Nav></Nav>
      
        <div class="darkmode-toggle">
          <label class="dm-checkbox" for="dmCheck">
            <input
              type="checkbox"
              id="dmCheck"
              checked={(gstate.theme === 'light') ? false : true}
              onChange={handleChange}
            >
            </input>
            <div class="image">

              <img src={darkmode} alt="darkmode" />
              <img src={lightmode} alt="lightmode" />

            </div>
            <span class="sr-only">Darkmode enabled</span>
          </label>


        </div>





      </div>


    </header>
  )

}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

import * as React from "react"
import { Link } from "gatsby"
//import useSiteMetadata from './use-site-metadata';
//import { siteMetadata } from "../../gatsby-config"
// import { globalHistory } from "@reach/router"
//import { getMainProps } from "gatsby-plugin-image/dist/src/components/hooks"

//look at :0- https://reach.tech/router/api/Link
//look at https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/
//look at https://stackoverflow.com/questions/63600734/gatsby-how-do-you-create-a-dynamic-nav-that-marks-the-current-page-as-active

import {useContext} from "react"
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider";

// import person from "../images/svg/person-explain.svg"

// import twitter from "../images/svg/social-twitter-white.svg"
// import linkedin from "../images/svg/social-linkedin-white.svg"
// import email from "../images/svg/email-sml-white.svg"
import TwitterButton from "./twitter-button";
import LinkedinButton from "./linkedin-button";


const Nav = () => {
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  //const path = globalHistory.location.pathname

  const closeNavbar = (e) => {
    console.log('click close nav');

      
        dispatch({type: 'MOBILE_NAV_OFF'})
      
    
  }

  
  return(
    <nav className="navigation-links" >
      

      <ul className="primary-navigation" id="primary-navigation" data-visible={state.mobile} > 
        
        <li className="hide-on-nav-small"><Link to="/" className="nav-link" onClick={closeNavbar}>Home</Link></li>
        <li><Link to="/blog" className="nav-link" onClick={closeNavbar}>Blog</Link></li>
        <li><Link to="/playground" className="nav-link" onClick={closeNavbar}>Playground</Link></li>
        <li><Link to="/contact" className="nav-link" onClick={closeNavbar}>Contact</Link></li>
        <li>
          {/* <button type="button" style={{height:`32px`}} onClick={() => 
dispatch({type: 'TOGGLE_THEME'})
}>{(state.theme==='light')?'🌑':'🌞'} theme</button> */}
</li>
{/* <div className="mobile-menu-content">
<h3>Sorry, there’s nothing<br/> to see at the minute</h3>
  <img src={person} alt="Person explaining" style={{width:"180px", margin:"0 auto"}} />
  <p>I’m working on it so, please<br/> come back soon.</p>
  </div> */}
  <ul className="mobile-only inline social">
        <li><TwitterButton /></li>
        <li><LinkedinButton /></li>
      </ul>
      </ul>
{/* <div className="mobile-contact">
      <ul className="inline social">
        <li><TwitterButton /></li>
        <li><LinkedinButton /></li>
      </ul>
      </div> */}

    

    </nav>
   
  )
}

export default Nav

import * as React from "react"
function LinkedinButton(){
    //const path = globalHistory.location.pathname
  
    return(
        <a href="https://www.linkedin.com/in/martin-fisher/" className="social-button" aria-label="Martin Fisher - Linkedin Profile">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M16 15.9852H12.685V10.7972C12.685 9.56016 12.663 7.96916 10.962 7.96916C9.237 7.96916 8.974 9.31716 8.974 10.7092V15.9852H5.662V5.31716H8.84V6.77616H8.886C9.328 5.93716 10.41 5.05216 12.023 5.05216C15.38 5.05216 16 7.26116 16 10.1342V15.9852ZM1.924 3.86016C0.859 3.86016 0 2.99816 0 1.93716C0 0.87616 0.859 0.0141602 1.924 0.0141602C2.984 0.0141602 3.845 0.87616 3.845 1.93716C3.845 2.99816 2.984 3.86016 1.924 3.86016V3.86016ZM3.582 15.9852H0.263V5.31716H3.582V15.9852Z"/>
</svg>


</a>  
      
    )
  }

  
  export default LinkedinButton
import * as React from "react"

import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "../scss/styles.scss"
import Helmet from 'react-helmet'


import {useContext} from "react"
import { GlobalStateContext } from "../context/GlobalContextProvider";


const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const state = useContext(GlobalStateContext);


  return (
    <>


    <Helmet>
    <body data-theme={state.theme} />
    </Helmet>

    
    
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      
      <div>
         
        
        <main className="typography">
        
          {children}
          </main>
        
    
      </div>
      
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

//determines if the user has a set theme
// function detectColorScheme(){
//   let theme="light";    //default to light

//   //local storage is used to override OS theme settings
//   if(localStorage.getItem("theme")){
//       if(localStorage.getItem("theme") === "dark"){
//            theme = "dark";
//       }
//   } else if(!window.matchMedia) {
//       //matchMedia method not supported
//       return false;
//   } else if(window.matchMedia("(prefers-color-scheme: dark)").matches) {
//       //OS theme setting detected as dark
//        theme = "dark";
//   }

//   //dark theme preferred, set document with a `data-theme` attribute
//   if (theme==="dark") {
//        document.documentElement.setAttribute("data-theme", "dark");
//   }
//   console.log(theme);
// }
// detectColorScheme();

export default Layout

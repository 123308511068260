
import * as React from "react"
function TwitterButton(){
    //const path = globalHistory.location.pathname
  
    return(
     
        <a href="https://twitter.com/humaanUX" className="social-button" aria-label="follow HumaanUX on twitter">
         <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.362 3.73705C14.368 3.87805 14.372 4.01905 14.372 4.16205C14.372 8.49905 11.07 13.501 5.032 13.501C3.178 13.501 1.453 12.958 0 12.027C0.257 12.057 0.518 12.072 0.783 12.072C2.321 12.072 3.736 11.548 4.86 10.667C3.423 10.641 2.211 9.69205 1.793 8.38805C1.994 8.42605 2.199 8.44605 2.411 8.44605C2.71 8.44605 3 8.40605 3.276 8.33105C1.774 8.03005 0.643 6.70305 0.643 5.11305C0.643 5.09905 0.643 5.08505 0.643 5.07105C1.085 5.31705 1.592 5.46505 2.13 5.48205C1.249 4.89305 0.669 3.88805 0.669 2.75005C0.669 2.14805 0.831 1.58405 1.114 1.09905C2.733 3.08505 5.152 4.39205 7.88 4.52905C7.824 4.28905 7.795 4.03805 7.795 3.78105C7.795 1.96805 9.265 0.498047 11.077 0.498047C12.022 0.498047 12.875 0.897047 13.473 1.53505C14.221 1.38805 14.924 1.11505 15.558 0.739047C15.313 1.50505 14.792 2.14805 14.115 2.55505C14.779 2.47505 15.411 2.29905 16 2.03805C15.56 2.69605 15.003 3.27405 14.362 3.73705"/>
</svg>
</a>

      
    )
  }

  
  export default TwitterButton